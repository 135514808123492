import React from 'react';

import "./styles.css";

function Footer() {
  return (
    <div className="footer">
      Feito com <span className="heart">♥</span> pelos noivos
    </div>
  );
};

export default Footer;
